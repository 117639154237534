




















































































import { Component, Provide, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { Route } from 'vue-router';
import {
  addDays, addMonths, differenceInCalendarDays, isAfter,
} from 'date-fns';
import store from '@/shared/store';
import { optionForDuration } from '@/shared/lib/budgetOptions';
import { campaignAudio } from '@/shared/lib/campaign';
import { isEnded, isInReview, Status } from '@/shared/lib/campaign_status';
import { saveSurveySeen, Survey, surveySeen } from '@/shared/lib/survey';
import {
  CampaignsTab, CancellationFee, Campaign,
} from '@/shared/store/campaign';
import { CampaignReport, ReportType } from '@/shared/store/campaignReports';
import AuthRequired from '@/shared/components/AuthRequired';
import LeafLayout from '@/shared/components/site/LeafLayout.vue';
import PageBody from '@/shared/components/campaign/results/PageBody.vue';
import FullScreenModal from '@/shared/components/common/FullScreenModal.vue';
import CancellationConfirmation from '@/zire/views/campaign/CancellationConfirmation.vue';
import ReleaseBrief from '@/shared/components/campaign/results/ReleaseBrief.vue';
import ReleaseDetails from '@/shared/components/campaign/results/ReleaseDetails.vue';
import SlideConfirmButton from '@/shared/components/campaign/results/SlideConfirmButton.vue';
import MobileNudge from '@/shared/components/common/MobileNudge.vue';
import defaultToast from '@/shared/lib/defaultToast';

@Component({
  components: {
    ReleaseBrief,
    ReleaseDetails,
    SlideConfirmButton,
    LeafLayout,
    PageBody,
    FullScreenModal,
    CancellationConfirmation,
    MobileNudge,
  },
})
export default class Results extends AuthRequired {
  $refs!: {
    exportButton: SlideConfirmButton,
    cancelButton: SlideConfirmButton,
    cancelConfirmationModal: FullScreenModal,
  };

  beforeRouteEnter(to: Route, from: Route, next: any) {
    Promise.all([
      store.dispatch('campaign/load', to.params.id),
      store.dispatch('campaignReports/getReports', {
        campaignId: to.params.id,
        reports: [ReportType.Campaign, ReportType.Daily],
      }),
    ])
      .then(() => {
        const c: Campaign = store.getters['campaign/campaignDetails'];
        if (c.status === Status.Rejected) {
          next({
            name: 'rejected-campaign',
            params: { id: c.id },
          });
        } else {
          next();
        }
      })
      .catch(() => next({ name: 'campaigns' }));
  }

  @Getter('campaign/campaignLoaded') campaignLoaded: boolean;

  @Getter('campaign/campaignDetails') campaign: Campaign;

  @Getter('profile/userId') userId: string;

  get isUnlocked() {
    return this.$store.getters['campaignReports/unlocked'](this.campaign.id);
  }

  get campaignReport(): CampaignReport | undefined {
    return this.$store.getters['campaignReports/campaignReport'](this.campaign.id);
  }

  cancellationFee: CancellationFee = { currency: '', amount: 0 };

  onCancelConfirmationClosed: Function[] = [];

  fadeHeader: boolean = false;

  get isEnded() {
    return isEnded(this.campaign.status!);
  }

  get isInReview() {
    return isInReview(this.campaign.status!);
  }

  get impressions() {
    const report = this.campaignReport;
    if (report) {
      return report.impressions;
    }
    return 0;
  }

  selected(action: string) {
    if (action === 'export' && this.$refs.cancelButton) {
      this.$refs.cancelButton.cancel();
    }
    if (action === 'cancel' && this.$refs.exportButton) {
      this.$refs.exportButton.cancel();
    }
    this.fadeHeader = true;
  }

  exportStatistics() {
    store.dispatch('campaignReports/requestExport', {
      campaignId: this.campaign.id,
      reports: [],
    }).then(() => {
      const numberUserImagesApplied = this.campaign.ugc!.images.length;
      const numberStockImagesApplied = this.campaign.ugc!.memberImages.length;
      const creativeControlMixpanelData = this.$store.getters['recommendedAds/getCreativeControlMixpanelData'];
      this.$store.dispatch('mixpanel/track', {
        properties: {
          releaseGenre: this.campaign.ugc!.fields.releaseGenre,
          appliedDurationDays: this.campaign.durationDays,
          numberUserImagesApplied,
          numberStockImagesApplied,
          numberTotalImagesApplied: numberUserImagesApplied + numberStockImagesApplied,
          numberUserCopyEditsApplied: creativeControlMixpanelData.numberUserCopyEditsApplied,
          numberUserDisabledAds: creativeControlMixpanelData.numberUserDisabledAds,
          numberIndividuallyEditedAds: creativeControlMixpanelData.numberUserCopyEditsApplied + creativeControlMixpanelData.numberUserDisabledAds,
          numberEnabledLocalAds: creativeControlMixpanelData.numberEnabledLocalAds,
          numberEnabledPlayableAds: creativeControlMixpanelData.numberEnabledPlayableAds,
          numberEnabledFeedbackAds: creativeControlMixpanelData.numberEnabledFeedbackAds,
          numberEnabledStreamingAds: creativeControlMixpanelData.numberEnabledStreamingAds,
          numberEnabledFBAds: creativeControlMixpanelData.numberEnabledFBAds,
          numberEnabledIGAds: creativeControlMixpanelData.numberEnabledIGAds,
          hasAudioApplied: this.campaign.ugc!.audio.length > 0,
          appliedAdNetworks: this.campaign.adNetworks,
          campaignStatus: this.campaign.status,
        },
        action: 'Zire.CampaignDataExported',
      });
      defaultToast('Your campaign data will be emailed to you within an hour.', 'success', 'far fa-stopwatch mr-2', 10000);
      this.fadeHeader = false;
    });
  }

  beginCancellation() {
    return this.$store.dispatch('campaign/beginCancellation', this.campaign.id)
      .then((cancellationFee) => {
        this.cancellationFee = cancellationFee;
        this.$refs.cancelConfirmationModal.show();
      })
      .catch(() => {
        defaultToast('Failed to cancel campaign.  Please try again.');
      });
  }

  cancelConfirmationClosed() {
    this.onCancelConfirmationClosed.forEach((i) => i());
    this.onCancelConfirmationClosed = [];
  }

  // exportStatistics() {
  // }

  @Provide('forceHiddenNavBar')
  forceHiddenNavBarInjection: Function = () => null;

  cancelCampaign() {
    return this.$store.dispatch('campaign/completeCancellation', this.campaign.id).then(() => {
      if (this.campaign.status === Status.Reviewing) {
        this.onCancelConfirmationClosed.push(() => {
          this.$store.dispatch('campaign/setSelectedTab', CampaignsTab.Completed).then(() => {
            this.$router.push({ name: 'campaigns' });
          });
        });
      }
      // Refresh the campaign...
      this.$store.dispatch('campaign/load', this.$route.params.id);
      this.fadeHeader = false;
    }).catch(() => {
      defaultToast('Failed to cancel campaign.  Please try again.');
    });
  }

  @Provide('cancelCampaign') cancelCampaignInjection: Function = this.cancelCampaign;

  submitFeedback(feedback: any) {
    return this.$store.dispatch('campaign/submitFeedback', feedback);
  }

  @Provide('submitFeedback') submitFeedbackInjection: Function = this.submitFeedback;

  mounted() {
    if (this.showSurvey && this.isTabletOrAbove) {
      const { campaign } = this;
      const budget = (campaign.budget!.amount / 100).toLocaleString(undefined, {
        style: 'currency',
        currency: campaign.budget!.currency,
      });
      const option = optionForDuration(campaign.durationDays!);
      const duration = option ? option.duration : '';
      const daysRun = differenceInCalendarDays(
        isEnded(campaign.status!) ? campaign.endedAt! : new Date(),
        campaign.activatedAt!,
      );
      store.dispatch('qualaroo/resultsSurvey', {
        campaign_id: campaign.id,
        user_id: this.userId,
        has_audio: !!campaignAudio(campaign),
        budget,
        duration,
        status: campaign.status,
        days_run: daysRun,
      });
      this.saveSurveySeen();
    } else if (this.showSurvey) {
      store.dispatch('survey/enableSurvey', Survey.Results);
    }
  }

  beforeDestroy() {
    store.dispatch('survey/dismissSurvey', Survey.Results);
  }

  @Getter('survey/showResultsSurvey') showResultsSurvey: boolean;

  @Watch('showResultsSurvey') resultsSurveyWatcher() {
    if (!this.showResultsSurvey) {
      this.resultsSurveyVisible = false;
    }
  }

  handleSurveyTriggerVisibility(visible: boolean) {
    if (visible && this.showResultsSurvey) {
      this.resultsSurveyVisible = true;
    }
  }

  resultsSurveyVisible: boolean = false;

  @Watch('resultsSurveyVisible') resultsSurveyVisibleWatcher() {
    if (this.resultsSurveyVisible) {
      this.$store.dispatch('mixpanel/track', {
        properties: {
          campaignId: this.campaign.id!,
        },
        action: 'Zire.ResultsPageMobileFeedbackNudgeSeen',
      });
      this.saveSurveySeen();
    }
  }

  saveSurveySeen() {
    saveSurveySeen(Survey.Results, this.campaign.id!);
    saveSurveySeen(Survey.Results, '', 1);
  }

  surveyDismissed() {
    this.fireNudgeClickedEvent('dismiss_button');
  }

  surveyClicked() {
    this.fireNudgeClickedEvent('primary_cta');
  }

  fireNudgeClickedEvent(btn: string) {
    this.$store.dispatch('mixpanel/track', {
      properties: {
        campaignId: this.campaign.id!,
        clickedItem: btn,
      },
      action: 'Zire.ResultsPageMobileFeedbackNudgeClicked',
    });
  }

  get resultsSurvey() {
    return Survey.Results;
  }

  get isTabletOrAbove() {
    return !['xs', 'sm'].includes(this.$mq);
  }

  get showSurvey() {
    const { campaign } = this;
    if (isInReview(campaign.status!)) {
      return false;
    }
    // do not show if they've seen a survey for this specific campaign
    if (surveySeen(Survey.Results, campaign.id!)) {
      return false;
    }
    // nor if they have seen a survey for any campaign in the past month (depends on cookie expiry)
    if (surveySeen(Survey.Results)) {
      return false;
    }
    if (campaign.endedAt && isAfter(new Date(), addMonths(campaign.endedAt, 1))) {
      return false;
    }
    if (campaign.status! === Status.Completed) {
      return true;
    }
    return campaign.durationDays! <= 7 && campaign.activatedAt && isAfter(new Date(), addDays(campaign.activatedAt, 5));
  }
}
