





























import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class SlideConfirmButton extends Vue {
  @Prop({ default: () => [] }) icon: string[];

  @Prop({ required: true }) prompt: string;

  @Prop({ default: '' }) variant: string;

  expanded: boolean = false;

  get hasIcon() {
    return this.icon && this.icon.length === 2;
  }

  mounted() {
    window.addEventListener('click', this.windowClick);
    window.addEventListener('scroll', this.handleScroll, { passive: true });
  }

  destroyed() {
    window.removeEventListener('click', this.windowClick);
    window.removeEventListener('click', this.handleScroll);
  }

  windowClick(evt: any) {
    if (evt.target !== this.target) {
      this.cancel();
    }
    this.target = null;
  }

  handleScroll() {
    this.cancel();
  }

  cancel() {
    this.$emit('cancel');
    this.expanded = false;
  }

  target: any = null;

  buttonClick(evt: any) {
    this.target = evt.target;
    if (this.expanded) {
      this.$emit('confirm');
      setTimeout(() => {
        this.expanded = false;
      }, 250);
    } else {
      this.$emit('select');
      this.expanded = true;
    }
  }
}
